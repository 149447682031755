import React from 'react';
import styles from './Map.module.scss';

function Map2022() {
    return (
        <div className={styles.imgbox}>
            <img className={styles.centerFit} style={{ maxHeight: '75vh' }} src="/Map_2022.svg" alt="Map 2022" />
        </div>
    );
}

export default Map2022;
