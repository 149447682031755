import React from 'react';
import styles from './Map.module.scss';

function Map2023() {
    return (
        <div className={styles.imgbox}>
            <img className={styles.centerFit} style={{ maxHeight: '70vh' }} src="/Map_2023.svg" alt="Map 2023" />
        </div>
    );
}

export default Map2023;
