import React from 'react';
import styles from './Map.module.scss';

function Map2021() {
    return (
        <div className={styles.imgbox}>
            <img className={styles.centerFit} src="/Map_2021.svg" alt="Map 2021" />
        </div>
    );
}

export default Map2021;
