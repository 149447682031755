import React from 'react';
import styles from './HallOfFame.module.scss';

function HallOfFame() {
    return (
        <div className={styles.niceFont}>
            <div>2019 Tommy</div>
            <div>2021 Magnus</div>
            <div>2022 Magnus</div>
            <div>2023 Tommy</div>
        </div>
    );
}

export default HallOfFame;
